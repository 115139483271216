import { z } from 'zod';

export const TimeZoneOffsets = [
  '+14',
  '+13:45',
  '+13',
  '+12:45',
  '+12',
  '+11',
  '+10:30',
  '+10',
  '+9:30',
  '+9',
  '+8:45',
  '+8',
  '+7',
  '+6:30',
  '+6',
  '+5:45',
  '+5:30',
  '+5',
  '+4:30',
  '+4',
  '+3:30',
  '+3',
  '+2',
  '+1',
  '0',
  '-1',
  '-2',
  '-2:30',
  '-3',
  '-3:30',
  '-4',
  '-5',
  '-6',
  '-7',
  '-8',
  '-9',
  '-9:30',
  '-10',
  '-11',
  '-12',
] as const;

export const TimeZoneNames = [
  'No Where',
  'Afghanistan Time',
  'Alaska Daylight Time',
  'Alaska Standard Time',
  'Anadyr Time',
  'Argentina Time',
  'Atlantic Standard Time',
  'Australian Central Daylight Saving Time',
  'Australian Central Standard Time',
  'Australian Central Western Standard Time',
  'Australian Eastern Daylight Saving Time',
  'Australian Eastern Standard Time',
  'Azores Standard Time',
  'Azores Summer Time',
  'Bougainville Standard Time',
  'British Time',
  'British Summer Time',
  'Cape Verde Time',
  'Central Africa Time',
  'Central Daylight Time',
  'Central European Summer Time',
  'Central European Time',
  'Central Standard Time',
  'Chatham Island Daylight Time',
  'Chatham Island Standard Time',
  'Chile Standard Time',
  'Chile Summer Time',
  'China Standard Time',
  'Cocos Islands Time',
  'East Africa Time',
  'Eastern Daylight Time',
  'Eastern European Summer Time',
  'Eastern European Time',
  'Eastern Standard Time',
  'Greenwich Mean Time',
  'Gulf Standard Time',
  'Hawaii–Aleutian Daylight Time',
  'Hawaii–Aleutian Standard Time',
  'India Standard Time',
  'Indian Chagos Time',
  'Iran Standard Time',
  'Japan Standard Time',
  'Korea Standard Time',
  'Line Islands Time',
  'Lord Howe Island Daylight Saving Time',
  'Lord Howe Island Time',
  'Marquesas Islands Time',
  'Mountain Daylight Time',
  'Mountain Standard Time',
  'Nepal Time',
  'New Zealand Daylight Time',
  'New Zealand Standard Time',
  'Newfoundland Daylight Time',
  'Newfoundland Standard Time',
  'Pacific Daylight Time',
  'Pacific Standard Time',
  'Pakistan Standard Time',
  'Phoenix',
  'Pierre & Miquelon Daylight Time',
  'Pierre & Miquelon Standard Time',
  'Samoa Standard Time',
  'South Georgia Time',
  'Western European Summer Time',
  'Western European Time',
  'Western Indonesian Time',
] as const;

export const TimeZoneAbbreviationValues = [
  '000',
  'ACDT',
  'ACST',
  'ACWST',
  'AEDT',
  'AEST',
  'AFT',
  'AKDT',
  'AKST',
  'ANAT',
  'ART',
  'AST',
  'AZOST',
  'AZOT',
  'AoE',
  'BST',
  'CAT',
  'CCT',
  'CDT',
  'CEST',
  'CET',
  'CHADT',
  'CHAST',
  'CLST',
  'CLT',
  'CST',
  'CVT',
  'EAT',
  'EDT',
  'EEST',
  'EET',
  'EST',
  'GMT',
  'GST',
  'HDT',
  'HST',
  'IOT',
  'IRST',
  'IST',
  'JST',
  'KST',
  'LHDT',
  'LHST',
  'LINT',
  'MDT',
  'MIT',
  'MST',
  'NDT',
  'NPT',
  'NST',
  'NZDT',
  'NZST',
  'PDT',
  'PKT',
  'PMDT',
  'PMST',
  'PST',
  'SST',
  'WEST',
  'WET',
  'WIB',
] as const;

export const TimeZoneIanas = [
  'not/found',
  'Africa/Cairo',
  'Africa/Maputo',
  'Africa/Nairobi',
  'Africa/Abidjan',
  'America/Adak',
  'America/Anchorage',
  'America/Argentina/Buenos_Aires',
  'America/Chicago',
  'America/Denver',
  'America/Los_Angeles',
  'America/Miquelon',
  'America/New_York',
  'America/Phoenix',
  'America/Puerto_Rico',
  'America/Santiago',
  'America/St_Johns',
  'Asia/Anadyr',
  'Asia/Colombo',
  'Asia/Dubai',
  'Asia/Jakarta',
  'Asia/Kabul',
  'Asia/Karachi',
  'Asia/Kathmandu',
  'Asia/Seoul',
  'Asia/Shanghai',
  'Asia/Tehran',
  'Asia/Tokyo',
  'Atlantic/Cape_Verde',
  'Atlantic/South_Georgia',
  'Australia/Adelaide',
  'Australia/Eucla',
  'Australia/Lord_Howe',
  'Australia/Sydney',
  'Etc/GMT+12',
  'Etc/GMT-14',
  'Europe/Brussels',
  'Europe/Lisbon',
  'Europe/London',
  'Indian/Chagos',
  'Indian/Cocos',
  'Pacific/Auckland',
  'Pacific/Chatham',
  'Pacific/Honolulu',
  'Pacific/Marquesas',
  'Pacific/Pago_Pago',
] as const;

export const TimeZoneAbbreviationsSchema = z.enum(TimeZoneAbbreviationValues);

// MomentJs does not support these values
export const TimeZoneNameSchema = z.enum(TimeZoneNames);

export const TimeZoneOffsetSchema = z.enum(TimeZoneOffsets);
// IANAS is the standard for time zones MomentJs property for these is 'name'
export const TimeZoneIanaSchema = z.enum(TimeZoneIanas);

export const TimeZoneSchema = z.object({
  abbr: TimeZoneAbbreviationsSchema,
  iana: TimeZoneIanaSchema,
  id: z.string().uuid(),
  momentName: z.string(),
  name: TimeZoneNameSchema,
  offset: TimeZoneOffsetSchema,
});
