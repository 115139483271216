export const colors = {
  /** ****
   UI Colors
   ***** */
  backgroundLight: '#ECEDEE',
  panelLight: '#FFFFFF',
  panelGray: '#ECF0F0',
  panelLightBlue: '#E5F0F3',
  panelSkyBlue: '#D5EAF3',
  panelBlue: '#364769',
  contentBreak: '#DFDFDF',
  contentBreakLight: '#E8EAEA',

  /** ****
 Control Architecture Colors
 ***** */
  controlPrimary: '#364769',
  controlHoverPrimary: '#5E6C87',
  controlActivePrimary: '#27447C',

  controlBrand: '#FF5A2D',
  controlHoverBrand: '#FF724C',
  controlActiveBrand: '#DF4F3C',

  controlSecondary: '#339ECC',
  controlHoverSecondary: '#57A1C1',
  controlActiveSecondary: '#1688B9',

  controlSelectLight: '#D5EAF3',
  controlHoverLight: '#E5F0F3',
  controlDisabled: '#DFDFDF',
  controlReverse: '#FFFFFF',
  controlInverse: '#E3ECEF',

  controlInverseHover: '#EDFAFE',
  controlInverseActive: '#C4D8E0',

  /** ****
 Text Colors
 ***** */
  textDefault: '#1C222E',
  textDisabled: '#CAD0D2',
  textLink: '#2E7999',
  textAlert: '#D11559',
  textAssistant: '#008554',
  textReversed: '#ffffff',
  textReversedSecondary: '#7D8694',

  /** ****
 Icon Colors
 ***** */
  iconDefault: '#1C222E',
  iconAlert: '#D11559',
  iconAssistant: '#008554',
  iconDisabled: '#DFDFDF',
  iconBrand: '#FF5A2D',
  iconReverse: '#FFFFFF',

  /** ****
 UI Assistant Colors
 ***** */
  uiAssistant: '#00AC6E',
  uiAssistantLight: '#C6E9D0',

  /** ****
 Badgers and Banners Colors
 ***** */
  controlInfoPrimary: '#349ECB',
  controlInfoSecondary: '#AADCEC',
  controlSuccessPrimary: '#00AC6E',
  controlSuccessSecondary: '#C6E9D0',
  controlAlertPrimary: '#D11559',
  controlAlertSecondary: '#FDDFDF',

  /** ****
 Data Visual Colors
 ***** */
  dataVizBlue: '#2C77E5',
  dataVizSky: '#88AEE5',
  dataVizOrange: '#FF5A2D',
  dataVizPeach: '#FFC8B9',
  dataVivGray: '#DFDFDF',
  dataVizYellow: '#FFBB00',
  dataVizPurple: '#7F4FD2',
  dataVizRed: '#F03235',
  dataVizGreen: '#81AE96',
  dataVizAvocado: '#BADC95',
  dataVizLightOrange: '#FFE6DF',
  dataVizLightBlue: '#E3ECEF',
  dataVizNavyBlue: '#364769',
  dataVizHotPink: '#D11559',
  dataVizForestGreen: '#00AC6E',
  dataVizTeal: '#349ECB',
  dataVizMatcha: '#C6E9D0',

  brandAxiomOrange: '#FF5A2D',
  /** ****
   Old values
   ***** */
  textSubtle: '#7D8694',
  textBrand: '#FF5A2D',
  textSecondary: '#477E96',

  controlDefault: '#6F768B',
  controlAlert: '#D11559',

  iconSecondary: '#A3ABB7',
  panelDark: '#1C222E',
  panelOrange: '#FF5A2D',
  panelLightOrange: '#FFE6DF',

  dataVizGray: '#CAD0D2',

  componentInactiveTab: '#2A6179',
} as const;

export const backgroundColorNames = {
  blue: 'blue',
  orange: 'orange',
  green: 'green',
  avocado: 'avocado',
  yellow: 'yellow',
  red: 'red',
  purple: 'purple',
  lightOrange: 'light orange',
  lightBlue: 'light blue',
  sky: 'sky',
  peach: 'peach',
  gray: 'gray',
  navyBlue: 'navy blue',
  hotPink: 'hot pink',
  forestGreen: 'forest green',
  teal: 'teal',
  matcha: 'matcha',
} as const;

export const shadows = {
  default: '0 1px 8px rgba(28, 34, 46, 0.15)',
  elevated: '0 2px 8px rgba(28, 34, 46, 0.5)',
} as const;

const oldColors = {
  axiomOrange: '#ff5a2d',
  brick: '#852b32',
  canary: '#fdd355',
  crimson: '#c93333',
  denim: '#477e96',
  forest: '#68855e',
  gold: '#ffbe0d',
  grape: '#7a5579',
  grass: '#b1c472',
  green: '#8CC63F',
  khaki: '#c2ba90',
  lavender: '#ab7eaa',
  olive: '#7c7562',
  rust: '#de5727',
  sienna: '#b84118',
  sky: '#a5d4d2',
  taupe: '#957d73',
  umber: '#5c4a4c',
  midwinterMist: '#a4d3de',
};

const neutrals = {
  lightest: '#fff',
  neutral10: '#ecf0f0',
  neutral20: '#dbe0e2',
  neutral30: '#bfc6cd',
  neutral40: '#a3abb7',
  neutral50: '#7d8694',
  neutral60: '#5b6471',
  neutral70: '#464e5b',
  neutral80: '#313844',
  neutral90: '#1c222e',
  darkest: '#000',
  malignant10: '#6c757d',
  malignant20: '#e9ecef',
  gray50: '#7F7F7F',
  malignant30: '#ccc',
  malignant40: 'rgba(101, 101, 101, 0.5)',
};

const spacing = {
  desktopPageBleed: '24px',
  tabletPageBleed: '24px',
  mobilePageBleed: '16px',
  desktopMaxWidth: '1368px',
};

const BASE_FONT_SIZE = '16px';

export const THEME_PALETTE_NAMES = {
  lightPalette: 'lightPalette',
  darkPalette: 'darkPalette',
};

export const THEME_BG_NAMES = {
  none: 'none',
  primary: 'primary',
  secondary: 'secondary',
  success: 'success',
};

export const AxiomTheme = {
  name: 'Axiom Theme',
  shadows: { ...shadows },
  colors: {
    ...colors,
    ...oldColors,
  },
  neutrals: {
    ...neutrals,
  },
  spacing: {
    ...spacing,
  },
  baseFontSize: BASE_FONT_SIZE,
  lightPalette: {
    name: 'Light Theme',
    baseFontSize: BASE_FONT_SIZE,
    colors: {
      ...colors,
      ...oldColors,
    },
    neutrals: {
      ...neutrals,
    },
    shadows: { ...shadows },
    spacing: {
      ...spacing,
    },
    text: {
      default: neutrals.neutral90,
      subtle: neutrals.neutral50,
      accent: neutrals.neutral90,
      success: neutrals.lightest,
      info: oldColors.denim,
      warning: oldColors.canary,
      error: oldColors.crimson,
      highlight: oldColors.axiomOrange,
    },
    backgrounds: {
      [THEME_BG_NAMES.primary]: 'inherit',
      [THEME_BG_NAMES.primary]: neutrals.lightest,
      [THEME_BG_NAMES.secondary]: neutrals.malignant20,
      [THEME_BG_NAMES.success]: oldColors.green,
    },
    interactions: {
      primary: {
        default: {
          text: neutrals.lightest,
          background: oldColors.axiomOrange,
          border: oldColors.axiomOrange,
        },
        hover: {
          text: neutrals.lightest,
          background: oldColors.axiomOrange,
          border: '#ef4d01',
        },
        active: {
          text: neutrals.lightest,
          background: '#ef4d01',
          border: '#ef4d01',
        },
        disabled: {
          text: neutrals.lightest,
          background: '#ff5a2d',
          border: '#ff5a2d',
        },
      },
      secondary: {
        default: {
          text: neutrals.lightest,
          background: neutrals.neutral80,
          border: neutrals.neutral80,
        },
        hover: {
          text: neutrals.lightest,
          background: neutrals.neutral90,
          border: neutrals.neutral90,
        },
        active: {
          text: neutrals.lightest,
          background: neutrals.neutral90,
          border: neutrals.neutral90,
        },
        disabled: {
          text: neutrals.lightest,
          background: neutrals.neutral70,
          border: neutrals.neutral70,
        },
      },
      tertiary: {
        default: {
          text: neutrals.darkest,
          background: neutrals.lightest,
          border: neutrals.neutral90,
        },
        hover: {
          text: neutrals.lightest,
          background: neutrals.neutral90,
          border: neutrals.neutral90,
        },
        active: {
          text: neutrals.lightest,
          background: neutrals.neutral90,
          border: neutrals.neutral90,
        },
        disabled: {
          text: neutrals.lightest,
          background: neutrals.neutral50,
          border: neutrals.neutral50,
        },
      },
      link: {
        default: {
          text: neutrals.lightest,
          background: 'transparent',
          border: 'transparent',
        },
        hover: {
          text: neutrals.lightest,
          background: 'transparent',
          border: 'transparent',
        },
        active: {
          text: neutrals.lightest,
          background: 'transparent',
          border: 'transparent',
        },
        disabled: {
          text: neutrals.lightest,
          background: 'transparent',
          border: 'transparent',
        },
      },
    },
  },
  darkPalette: {
    name: 'Dark Theme',
    baseFontSize: BASE_FONT_SIZE,
    colors: {
      ...colors,
      ...oldColors,
    },
    shadows: { ...shadows },
    spacing: {
      ...spacing,
    },
    neutrals: {
      ...neutrals,
    },
    text: {
      default: neutrals.lightest,
      subtle: neutrals.neutral40,
      accent: neutrals.neutral50,
      info: oldColors.denim,
      warning: oldColors.canary,
      error: oldColors.crimson,
      highlight: oldColors.axiomOrange,
    },
    backgrounds: {
      [THEME_BG_NAMES.primary]: neutrals.neutral90,
      [THEME_BG_NAMES.secondary]: neutrals.neutral70,
      [THEME_BG_NAMES.success]: oldColors.green,
    },
    interactions: {
      primary: {
        default: {
          text: neutrals.lightest,
          background: oldColors.axiomOrange,
          border: oldColors.axiomOrange,
        },
        hover: {
          text: neutrals.lightest,
          background: '#ef4d01',
          border: '#ef4d01',
        },
        active: {
          text: neutrals.lightest,
          background: '#ef4d01',
          border: '#ef4d01',
        },
        disabled: {
          text: neutrals.lightest,
          background: '#ff5a2d',
          border: '#ff5a2d',
        },
      },
      secondary: {
        default: {
          text: neutrals.lightest,
          background: neutrals.neutral80,
          border: neutrals.neutral80,
        },
        hover: {
          text: neutrals.lightest,
          background: neutrals.neutral90,
          border: neutrals.neutral90,
        },
        active: {
          text: neutrals.lightest,
          background: neutrals.neutral90,
          border: neutrals.neutral90,
        },
        disabled: {
          text: neutrals.lightest,
          background: neutrals.neutral70,
          border: neutrals.neutral70,
        },
      },
      tertiary: {
        default: {
          text: neutrals.darkest,
          background: neutrals.lightest,
          border: neutrals.neutral90,
        },
        hover: {
          text: neutrals.lightest,
          background: neutrals.neutral90,
          border: neutrals.neutral90,
        },
        active: {
          text: neutrals.lightest,
          background: neutrals.neutral90,
          border: neutrals.neutral90,
        },
        disabled: {
          text: neutrals.lightest,
          background: neutrals.neutral50,
          border: neutrals.neutral50,
        },
      },
      link: {
        default: {
          text: neutrals.lightest,
          background: 'transparent',
          border: 'transparent',
        },
        hover: {
          text: neutrals.lightest,
          background: 'transparent',
          border: 'transparent',
        },
        active: {
          text: neutrals.lightest,
          background: 'transparent',
          border: 'transparent',
        },
        disabled: {
          text: neutrals.lightest,
          background: 'transparent',
          border: 'transparent',
        },
      },
    },
  },
} as const;

export const loadLightPalette = () => ({
  _currentPalette: THEME_PALETTE_NAMES.lightPalette,
  name: 'Axiom Theme',
  baseFontSize: BASE_FONT_SIZE,
  neutrals: { ...neutrals },
  colors: { ...colors, ...oldColors },
  shadows: { ...shadows },
  spacing: { ...spacing },
  ...AxiomTheme.lightPalette,
});

export const loadDarkPalette = () => ({
  _currentPalette: THEME_PALETTE_NAMES.darkPalette,
  name: 'Axiom Theme',
  baseFontSize: BASE_FONT_SIZE,
  neutrals: { ...neutrals },
  colors: { ...colors, ...oldColors },
  shadows: { ...shadows },
  spacing: { ...spacing },
  ...AxiomTheme.darkPalette,
});
