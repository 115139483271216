import { selfServiceHelpRequiredValues } from '@axiom/const';
import { ZodArrayToEnum } from '@axiom/types';
import { z } from 'zod';

const selfServiceHelpRequest = Object.keys(
  selfServiceHelpRequiredValues
) as Array<keyof typeof selfServiceHelpRequiredValues>;
export const SelfServiceHelpRequestSchema = z.object({
  helpRequired: z.enum(ZodArrayToEnum(selfServiceHelpRequest)),
});
