import { UserConst } from '@axiom/const';
import { z } from 'zod';
import { SchemaEmail, SchemaTimestamp, ZodArrayToEnum } from '@axiom/types';

export const RolesValues = Object.values(
  UserConst.Roles
) as NonEmptyArray<string>;

export const BaseUserRolesType = z.enum(ZodArrayToEnum(RolesValues));
export const BaseUserSchema = z.object({
  bullhornId: z.string().max(255).nullable(),
  candidateId: z.string().uuid().nullish(),
  contactId: z.string().uuid().nullish(),
  created_at: SchemaTimestamp,
  email: SchemaEmail.nullable(),
  firstLoginAt: SchemaTimestamp.nullable(),
  firstName: z.string().trim().max(255).nullable(),
  fullName: z.string().max(255).nullable(),
  hasAcceptedTermsAndConditions: z.boolean().default(false),
  homeOffice: z.string().max(255).nullable(),
  id: z.string().uuid(),
  integrationId: z.string().max(255).nullable(),
  isActive: z.boolean().nullable(),
  lastLoginAt: SchemaTimestamp.nullable(),
  lastName: z.string().trim().max(255).nullable(),
  organizationId: z.string().uuid().nullish(),
  password: z.string().max(255).nullable(),
  retryAttempts: z.number(),
  roles: z.array(BaseUserRolesType),
  salesforceId: z.string().max(255).nullable(),
  termsAndConditionsAcceptedAt: SchemaTimestamp,
  updated_at: SchemaTimestamp,
});
