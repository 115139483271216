import moment from 'moment';
import { SchemaDate, SchemaLocation } from '@axiom/types';
import { z } from 'zod';
import { IndustryValue, PositionsConst } from '@axiom/const';

import {
  LevelOfExperience,
  levelOfExperienceValues,
  LicensedLawyer,
  licensedLawyerValues,
  Occupation,
  occupationTypeValues,
  positionReservedTypeValues,
  PositionReserved,
} from '@axiom/validation';

export const billingUnitValues = [5, 10, 15, 20, 25, 30, 35, 40] as const;

export const worksiteValues = {
  [PositionsConst.Worksites.Remote]: 'No, remote is fine',
  [PositionsConst.Worksites.OnsiteRemote]: 'Yes, periodically in our office',
  [PositionsConst.Worksites.Onsite]: 'Yes, full time in our office',
} as const;

export const selfServiceDescriptionMaxLength = 150;

export const ProjectNeedsSchema = z.object({
  billingUnitsPerWeek: z.number().gte(5).lte(40),
  companyLocation: SchemaLocation.nullish(),
  description: z.string().max(selfServiceDescriptionMaxLength).nullish(),
  endDate: SchemaDate,
  generalSkills: z.array(z.string()).nullish(),
  industry: z
    .enum(Object.keys(IndustryValue) as NonEmptyArray<IndustryValue>)
    .nullish(),
  languageId: z.string().uuid().nullish(),
  legalTechSkills: z.array(z.string()).nullish(),
  levelOfExperience: z.enum(
    Object.keys(levelOfExperienceValues) as NonEmptyArray<LevelOfExperience>
  ),
  licensedLawyer: z.enum(
    Object.keys(licensedLawyerValues) as NonEmptyArray<LicensedLawyer>
  ),
  licensedLawyerState: z.string(),
  maxBudgetPerHour: z
    .number({
      invalid_type_error: 'Must be more than $100',
    })
    .positive()
    .gte(100, { message: 'Must be more than $100' })
    .max(99999999, { message: 'Must be less than $99,999,999' }),
  minBudgetPerHour: z
    .number({
      invalid_type_error: 'Enter a number over $0',
    })
    .positive()
    .gte(1, { message: 'Enter a number over $0' })
    .max(99999999, { message: 'Must be less than $99,999,999' }),
  occupationType: z.enum(
    Object.keys(occupationTypeValues) as NonEmptyArray<Occupation>
  ),
  otherFocusAreas: z.array(z.string()).nullish(),
  primaryFocusArea: z.string(),
  reservedType: z.enum(
    Object.keys(positionReservedTypeValues) as NonEmptyArray<PositionReserved>
  ),
  startDate: SchemaDate.superRefine((value, ctx) => {
    if (moment().isAfter(moment(value), 'day')) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Must be today or after',
      });
    }
  }),
  worksite: z.enum(Object.keys(worksiteValues) as NonEmptyArray<string>),
});

export type WorkSiteValue =
  (typeof worksiteValues)[keyof typeof worksiteValues];
