import { Taxonomy } from '@axiom/validation';

export const TaxonomyUtil = {
  getGeneralSkillEnums: (generalSkills: Taxonomy['generalSkills']) => {
    return Object.keys(generalSkills).sort();
  },

  getGeneralSkillOptions: (generalSkills: Taxonomy['generalSkills']) => {
    return TaxonomyUtil.getGeneralSkillEnums(generalSkills).map(key => ({
      label: generalSkills[key].displayName,
      value: key,
    }));
  },

  getIndustryEnums: (industries: Taxonomy['industries']) => {
    return Object.keys(industries).sort();
  },

  getIndustryOptions: (industries: Taxonomy['industries']) => {
    return TaxonomyUtil.getIndustryEnums(industries).map(key => ({
      label: industries[key].displayValue,
      value: key,
    }));
  },

  getFilteredIndustryOptions: (industries: Taxonomy['industries']) => {
    return TaxonomyUtil.getIndustryEnums(industries)
      .filter(opt => opt !== 'NA')
      .map(key => ({
        label: industries[key].displayValue,
        value: key,
      }));
  },

  getPracticeAreaEnums: (practiceAreas: Taxonomy['practiceAreas']) => {
    return Object.keys(practiceAreas);
  },

  getPracticeAreaOptions: (practiceAreas: Taxonomy['practiceAreas']) => {
    return TaxonomyUtil.getPracticeAreaEnums(practiceAreas).map(key => ({
      label: practiceAreas[key].fullName,
      value: key,
    }));
  },

  getFocusAreaEnums: (focusAreas: Taxonomy['focusAreas']) => {
    return Object.keys(focusAreas);
  },

  getFocusAreaOptions: (
    focusAreas: Taxonomy['focusAreas'],
    practiceAreas: Taxonomy['practiceAreas']
  ) => {
    return TaxonomyUtil.getFocusAreaEnums(focusAreas).map(key => ({
      label: `${
        TaxonomyUtil.getPracticeAreaOptions(practiceAreas).find(
          practiceArea => practiceArea.value === focusAreas[key].practiceArea
        ).label
      } > ${focusAreas[key].fullName}`,
      value: key,
    }));
  },

  getFocusAreaSkillGroups: (
    focusArea: string,
    skillGroups: Taxonomy['skillGroups']
  ) => {
    return TaxonomyUtil.getSkillGroupEnums(skillGroups)
      .map(key => ({
        key,
        ...skillGroups[key],
      }))
      .filter(skillGroup => skillGroup.focusArea === focusArea);
  },

  getSkillGroupEnums: (skillGroups: Taxonomy['skillGroups']) => {
    return Object.keys(skillGroups);
  },

  getLegalSkillEnums: (legalSkills: Taxonomy['legalSkills']) => {
    return Object.keys(legalSkills);
  },

  getLegalSkillOptions: (
    legalSkills: Taxonomy['legalSkills'],
    skillGroup: string
  ) => {
    return TaxonomyUtil.getLegalSkillEnums(legalSkills)
      .map(key => ({
        key,
        ...legalSkills[key],
      }))
      .filter(legalSkill => legalSkill.skillGroup === skillGroup)
      .map(legalSkill => ({
        label: legalSkill.fullName,
        value: legalSkill.key,
      }));
  },

  getLegalTechSkillEnums: (legalTechSkills: Taxonomy['legalTechSkills']) => {
    return Object.keys(legalTechSkills);
  },

  getLegalTechSkillOptions: (legalTechSkills: Taxonomy['legalTechSkills']) => {
    return TaxonomyUtil.getLegalTechSkillEnums(legalTechSkills).map(key => ({
      label: legalTechSkills[key].displayName,
      value: key,
    }));
  },
};
