import Ajv from 'ajv';
import { z } from 'zod';
import { SchemaEmail, SchemaTimestamp } from '@axiom/types';

import { axiomValidationOptions } from './options';
import { BaseUserSchema } from './base-user';

const ajv = new Ajv({
  ...axiomValidationOptions(),
});

export const ContactSchema = z.object({
  id: z.string().uuid(),
  address: z.string().min(1).max(255).nullable(),
  availabilityNotificationOptOut: z.boolean().nullable(),
  benchNotificationOptOut: z.boolean().nullable(),
  canViewPricing: z.boolean().default(false),
  city: z.string().min(1).max(255).nullable(),
  country: z.string().min(1).max(255).nullable(),
  createdAt: SchemaTimestamp,
  displayRole: z.string().nullable(),
  email: SchemaEmail.nullable(),
  firstName: z.string().min(1).max(255).nullable(),
  fullName: z.string().min(1).max(255).nullable(),
  lastName: z.string().min(1).max(255).nullable(),
  mobileNumber: z.string().min(1).max(255).nullable(),
  phoneNumber: z.string().min(1).max(255).nullable(),
  salesforceId: z.string().min(1).max(255).nullable(),
  spvCount: z.number().nullable(),
  spvCountCycleDts: SchemaTimestamp.nullable(),
  state: z.string().min(1).max(255).nullable(),
  updatedAt: SchemaTimestamp.nullable(),
  zip: z.string().min(1).max(255).nullable(),
});

export const AccountContactSchema = ContactSchema.extend({
  user: BaseUserSchema,
});

const commonAjv = new Ajv(axiomValidationOptions());

// VALIDATIONS
const contactCommonValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    firstName: { type: ['string', 'null'], minLength: 1, maxLength: 255 },
    lastName: { type: ['string', 'null'], minLength: 1, maxLength: 255 },
    address: { type: ['string', 'null'], minLength: 1, maxLength: 255 },
    city: { type: ['string', 'null'], minLength: 1, maxLength: 255 },
    state: { type: ['string', 'null'], minLength: 1, maxLength: 255 },
    zip: { type: ['string', 'null'], minLength: 1, maxLength: 255 },
    country: { type: ['string', 'null'], minLength: 1, maxLength: 255 },
    email: { type: ['string', 'null'], minLength: 1, maxLength: 255 },
    mobileNumber: { type: ['string', 'null'], minLength: 1, maxLength: 255 },
    phoneNumber: { type: ['string', 'null'], minLength: 1, maxLength: 255 },
    salesforceId: { type: ['string', 'null'], minLength: 1, maxLength: 255 },
    availabilityNotificationOptOut: { type: ['boolean', 'null'] },
    benchNotificationOptOut: { type: ['boolean', 'null'] },
    canViewPricing: { type: ['boolean'] },
  },
};

const clientUserUpdateContactValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    availabilityNotificationOptOut: { type: ['boolean', 'null'] },
    benchNotificationOptOut: { type: ['boolean', 'null'] },
  },
};

const contactSignUpValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    companyName: { type: ['string', 'null'], minLength: 1, maxLength: 255 },
    email: {
      type: ['string', 'null'],
      format: 'email',
      minLenth: 1,
      maxLength: 255,
    },
    firstName: { type: ['string', 'null'], minLength: 1, maxLength: 255 },
    lastName: { type: ['string', 'null'], minLength: 1, maxLength: 255 },
    termsAndConditionsAcceptedAt: {
      type: ['string', 'null'],
      format: 'date-time',
    },
  },
  required: [
    'companyName',
    'email',
    'firstName',
    'lastName',
    'termsAndConditionsAcceptedAt',
  ],
};

const contactsQueryValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    search: { type: 'string' },
    page: { type: ['integer', 'string'], default: 1 },
    sort: { type: 'string', default: 'lastName' },
    filters: {
      additionalProperties: false,
      type: 'object',
      properties: {
        salesforceId: {
          type: 'array',
          items: {
            type: 'string',
            format: 'uuid',
          },
        },
        accounts: {
          type: 'array',
          items: {
            type: 'string',
            format: 'uuid',
          },
        },
        user: {
          type: 'string',
          format: 'uuid',
        },
      },
    },
  },
};

// VALIDATORS
export const createSalesforceContactValidator = commonAjv.compile(
  contactCommonValidation
);

export const updateSalesforceContactValidatorCreator = ({
  ajvInstance = ajv,
  additionalOpts = {},
} = {}) =>
  ajvInstance.compile({
    ...contactCommonValidation,
    ...additionalOpts,
    anyOf: Object.keys(contactCommonValidation.properties).map(key => ({
      required: [key],
    })),
  });

export const updateSalesforceContactValidator =
  updateSalesforceContactValidatorCreator();

// ONLY CONDITIONALLY USE THIS - for boomi endpoints only
export const updateSalesforceContactAdditionalPropertiesValidator =
  updateSalesforceContactValidatorCreator({
    ajvInstance: new Ajv({
      ...axiomValidationOptions(),
      removeAdditional: true,
    }),
    additionalOpts: {
      additionalProperties: false,
    },
  });

export const signUpContactValidator = ajv.compile(contactSignUpValidation);

export const clientUserUpdateContactValidator = ajv.compile(
  clientUserUpdateContactValidation
);

export const contactsQueryValidator = ajv.compile(contactsQueryValidation);
