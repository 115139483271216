import { z } from 'zod';
import { SchemaEmail, SchemaTimestamp } from '@axiom/types';

import { OpportunitySchema } from './opportunity';
import { TimeZoneIanaSchema } from './time-zone';

export const CalendarEventStates = [
  'saved',
  'new',
  'modified',
  'delete',
] as const;
export const CalendarEventAttendeeUserTypes = ['Talent', 'Client'] as const;

export const CalendarEventAttendeeSchema = z.object({
  email: SchemaEmail.nullable(),
  name: z.string(),
  userType: z.enum(CalendarEventAttendeeUserTypes).nullable(),
});

const CalendarEventBaseSchema = z.object({
  accountName: z.string().nullish(),
  attendees: z.array(CalendarEventAttendeeSchema).nullish(),
  busy: z.boolean(),
  error: z.string().nullish(),
  id: z.string().nullish(),
  name: z.string().nullish(),
  state: z.enum(CalendarEventStates),
  title: z.string().nullish(),
});

export const CalendarEventSchema = CalendarEventBaseSchema.extend({
  endTime: SchemaTimestamp,
  startTime: SchemaTimestamp,
});

export const CalendarComponentEventSchema = CalendarEventBaseSchema.extend({
  end: z.date(),
  start: z.date(),
  isBackgroundEvent: z.boolean().nullable(),
});

const CalendarBaseSchema = z.object({
  candidateId: z.string().uuid().nullable(),
  contactId: z.string().uuid().nullable(),
  id: z.string().uuid().nullable(),
  nylasCalendarId: z.string().nullable(),
  timezone: TimeZoneIanaSchema,
});

export const CalendarFreeTimeUpdatedUserTypes = ['Talent', 'HQ'] as const;
export const CalendarSchema = CalendarBaseSchema.extend({
  events: z.array(CalendarEventSchema).nullable(),
  freeTimeUpdatedAt: SchemaTimestamp.nullable(),
  freeTimeUpdatedUserType: z.enum(CalendarFreeTimeUpdatedUserTypes).nullable(),
});

export const CalendarManualInterviewAttendeeSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: SchemaEmail,
});

export const CalendarManualInterviewSchema = CalendarBaseSchema.pick({
  candidateId: true,
})
  .merge(
    OpportunitySchema.pick({
      submissionId: true,
    })
  )
  .extend({
    adhocAttendees: z.array(CalendarManualInterviewAttendeeSchema).nullable(),
    availabilityNote: z.string().nullish(),
    contactIds: z.array(z.string().uuid()).nullable(),
  });

export const CalendarScheduledInterviewSchema =
  CalendarManualInterviewSchema.merge(
    CalendarEventSchema.pick({
      title: true,
      endTime: true,
      startTime: true,
    })
  );
